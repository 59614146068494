import { Directive, inject, input } from '@angular/core';
import { ImagePreviewDialogService } from './image-preview-dialog.service';

@Directive({
  selector: 'ion-img[dojoImagePreview][src], img[dojoImagePreview][src], [dojoImagePreview][src]',
  standalone: true,
  host: {
    '(click)': 'imagePreviewDialogService.openDialog({ input: srcUrl() })',
    class: 'cursor-pointer',
    '[attr.src]': 'srcUrl()',
  },
  providers: [ImagePreviewDialogService],
})
export class ImagePreviewDirective {
  readonly imagePreviewDialogService: ImagePreviewDialogService = inject(ImagePreviewDialogService);
  readonly srcUrl = input.required<string>({
    // eslint-disable-next-line @angular-eslint/no-input-rename
    alias: 'src',
  });
}
