import { Component, Input } from '@angular/core';
import { SwiperComponent, SwiperSlideDirective } from '@examdojo/core/swiper';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'dojo-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styles: [
    `
      .preview-image-slider ::ng-deep swiper-container {
        height: 100%;
      }

      ::ng-deep ion-modal.image-preview-dialog {
        @media only screen and (min-width: 768px) {
          --height: auto;
          --max-height: calc(100% - 64px);
        }
      }
    `,
  ],
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IconButtonComponent,
    SwiperComponent,
    SwiperSlideDirective,
    SwiperComponent,
    SwiperSlideDirective,
    TranslocoPipe,
  ],
})
export class ImagePreviewDialogComponent {
  constructor(private readonly modalCtrl: ModalController) {}

  @Input() imageUrl?: string;

  readonly swiperOptions: SwiperOptions = {
    slidesPerView: 'auto',
    zoom: true,
    freeMode: true,
  };

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
