<ion-header>
  <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
    <div slot="start" class="hidden md:flex">
      <dojo-icon-button (click)="closeModal()" [icon]="'times'" />
    </div>
    <ion-title class="common-ion-title">{{ 'examdojo.preview_image' | transloco }}</ion-title>
    <div slot="end" class="hidden md:flex md:w-8"></div>
  </ion-toolbar>
</ion-header>

<div class="flex h-full justify-center">
  <dojo-swiper [options]="swiperOptions" class="preview-image-slider min-h-[100px] w-full">
    <swiper-slide>
      <div class="swiper-zoom-container">
        <img style="max-height: 100vh; object-fit: contain" [src]="imageUrl" class="w-full" />
      </div>
    </swiper-slide>
  </dojo-swiper>
</div>
