import { Injectable } from '@angular/core';
import { AbstractDialogService, DialogOptions } from '@examdojo/core/dialog';
import { ImagePreviewDialogComponent } from './image-preview-dialog.component';

@Injectable()
export class ImagePreviewDialogService extends AbstractDialogService<string> {
  readonly id = 'image-preview-dialog';
  protected readonly component = ImagePreviewDialogComponent;

  override readonly options = {
    cssClass: 'modified-modal font-primary-exam action-sheet-on-mobile image-preview-dialog',
  };

  override openDialog({ input: imageUrl }: { input: string; options?: DialogOptions }) {
    return super.openDialog({ options: { ...this.options, componentProps: { imageUrl } } });
  }
}
